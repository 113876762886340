import React from 'react';
import { Link } from 'react-scroll';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:grid md:grid-cols-2">
          <div className="mb-6 md:mb-0">
            <a href="https://funlings-ent.com/" className="flex items-center">
              <img
                src="/images/logo.png"
                className="h-20 mr-3"
                alt="FlowBite Logo"
              />
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold  uppercase text-white">
                Resources
              </h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-4">
                  <Link
                    to={'partners'}
                    spy={true}
                    smooth={true}
                    offset={-40}
                    duration={900}
                    className="hover:underline"
                  >
                    Funlings
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to={'partners'}
                    spy={true}
                    smooth={true}
                    offset={-40}
                    duration={900}
                    className="hover:underline"
                  >
                    Partners
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to={'blog'}
                    spy={true}
                    smooth={true}
                    offset={-40}
                    duration={900}
                    className="hover:underline"
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <a href="/#/Admin">CRM</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-white">
                Follow us
              </h2>
              <ul className=" text-gray-400 font-medium">
                <li className="mb-4">
                  <a
                    href="https://www.facebook.com/funlingsedutainment"
                    className="hover:underline"
                  >
                    Facebook
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://www.instagram.com/funlings_edutainment"
                    className="hover:underline "
                  >
                    Instagram
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://www.linkedin.com/company/funlings-entertainment/"
                    className="hover:underline"
                  >
                    LinkedIn
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://twitter.com/funlingsent"
                    className="hover:underline"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCBhyGBvo5pZ0eysGnuF00pw"
                    className="hover:underline"
                  >
                    Youtube
                  </a>
                </li>
              </ul>
            </div>
            {/* <div>
              <h2 className="mb-6 text-sm font-semibold  uppercase text-white">
                Legal
              </h2>
              <ul className="text-gray-400 font-medium">
                <li className="mb-4">
                  <a href="#" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    data-modal-target="defaultModal"
                    data-modal-toggle="defaultModal"
                    className="hover:underline"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <hr className="my-6  sm:mx-auto border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm  sm:text-center text-gray-400">
            © {year}{' '}
            <a href="https://funlings.com/" className="hover:underline">
              Funlings™
            </a>
            . All Rights Reserved.
          </span>
          <div className="hidden md:flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a
              href="https://www.facebook.com/funlingsedutainment"
              className="text-gray-500 hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className="sr-only">Facebook page</span>
            </a>
            <a
              href="https://www.instagram.com/funlings_edutainment"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
              <span className="sr-only">Instagram page</span>
            </a>
            <a
              href="https://www.linkedin.com/company/funlings-entertainment/"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M12.979 9V10.586C13.3122 10.079 13.7715 9.66737 14.3119 9.39143C14.8522 9.11549 15.4549 8.98474 16.061 9.012C19.3 9.012 20 11.03 20 13.655V19H17V14.262C17 13.132 16.771 11.678 15.005 11.678C13.292 11.678 13 12.908 13 14.178V19H10.009V9H12.979Z" />
                <path d="M8 6.487C8 6.78367 7.91203 7.07368 7.74721 7.32035C7.58238 7.56703 7.34811 7.75929 7.07403 7.87282C6.79994 7.98635 6.49834 8.01606 6.20737 7.95818C5.91639 7.9003 5.64912 7.75744 5.43934 7.54766C5.22956 7.33788 5.0867 7.07061 5.02882 6.77963C4.97094 6.48866 5.00065 6.18706 5.11418 5.91297C5.22771 5.63889 5.41997 5.40462 5.66665 5.2398C5.91332 5.07497 6.20333 4.987 6.5 4.987C6.89783 4.987 7.27936 5.14503 7.56066 5.42634C7.84197 5.70764 8 6.08917 8 6.487Z" />
                <path d="M8 9.012H5V19H8V9.012Z" />
              </svg>
              <span className="sr-only">LinkedIn page</span>
            </a>
            <a
              href="https://twitter.com/funlingsent"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
              <span className="sr-only">Twitter page</span>
            </a>
            <a
              href="https://www.youtube.com/channel/UCBhyGBvo5pZ0eysGnuF00pw"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M21.7001 8.037C21.6065 7.32638 21.3351 6.65086 20.9111 6.073C20.3856 5.54364 19.6729 5.24227 18.9271 5.234C16.1601 5.034 12.0011 5.034 12.0011 5.034C12.0011 5.034 7.8441 5.034 5.0731 5.234C4.32751 5.24211 3.6151 5.54352 3.0901 6.073C2.66421 6.6503 2.39233 7.32655 2.3001 8.038C2.17651 9.10235 2.10974 10.1725 2.1001 11.244V12.744C2.10968 13.8155 2.17644 14.8856 2.3001 15.95C2.3941 16.6621 2.6635 17.3397 3.0841 17.922C3.68779 18.4578 4.46398 18.7588 5.2711 18.77C6.8541 18.921 12.0021 18.97 12.0021 18.97C12.0021 18.97 16.1631 18.97 18.9301 18.77C19.6763 18.7613 20.3893 18.4596 20.9151 17.93C21.3382 17.3514 21.6088 16.6757 21.7021 15.965C21.8257 14.9006 21.8925 13.8305 21.9021 12.759V11.243C21.8908 10.1715 21.8234 9.1014 21.7001 8.037ZM10.0081 14.591V8.971L15.4081 11.79L10.0081 14.591Z" />
              </svg>
              <span className="sr-only">Youtube page</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
