import React, { useState } from 'react';
import { LockClosedIcon, UserIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error('Please enter both email and password');
      return;
    }
    toast.error('Reequest failed! Please try again later.');
    console.log({ email, password, rememberMe });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-600 to-blue-500">
      <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-xl transform transition-all hover:scale-105">
        <h2 className="text-3xl font-extrabold text-center text-gray-900">
          Admin Login
        </h2>
        <p className="text-sm text-center text-gray-600">
          Enter your credentials
        </p>

        <form className="mt-6" onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">
              Email Address
            </label>
            <div className="flex items-center border rounded-lg px-3 py-2 mt-1 bg-gray-100">
              <UserIcon className="w-5 h-5 text-gray-500" />
              <input
                type="email"
                className="w-full ml-2 border-none focus:ring-0 bg-transparent"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">
              Password
            </label>
            <div className="flex items-center border rounded-lg px-3 py-2 mt-1 bg-gray-100">
              <LockClosedIcon className="w-5 h-5 text-gray-500" />
              <input
                type="password"
                className="w-full ml-2 border-none focus:ring-0 outline-none bg-transparent"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <label className="flex items-center text-gray-600">
              <input
                type="checkbox"
                className="mr-2 accent-purple-600"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              Remember me
            </label>
          </div>

          <button className="w-full bg-purple-600 text-white py-3 rounded-lg font-bold text-lg hover:bg-purple-700 transition shadow-md">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
