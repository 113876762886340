import React, { useEffect, useState } from 'react';
import {
  Calendar,
  Trophy,
  Code2,
  Sprout,
  ChevronDown,
  Globe,
  Users,
  Sparkles,
  Gamepad2,
  Rocket,
  Stars,
  Brain,
  Lightbulb,
  Heart,
  X,
} from 'lucide-react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

function Competitions() {
  const [isVisible, setIsVisible] = useState(false);
  // const [timeLeft, setTimeLeft] = useState({
  //   days: 0,
  //   hours: 0,
  //   minutes: 0,
  //   seconds: 0,
  // });
  const [isloading, setIsLoading] = useState(false);
  //const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  // const handleRegister = (e) => {
  //   e.preventDefault();
  //   // Prevent the default form submission behavior
  //   setIsLoading(true);
  //   emailjs
  //     .sendForm(
  //       'service_vjyk2w7',
  //       'template_ld9mii8',
  //       e.target,
  //       'wmHveuk1GLXiFJ9Ur',
  //     )
  //     .then(
  //       (result) => {
  //         setIsLoading(false);
  //         setIsRegisterOpen(false);
  //         Swal.fire({
  //           title: 'Registration Successful!',
  //           text: 'Thank you for registering!',
  //           icon: 'success',
  //           timer: 2000,
  //         });
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //         setIsRegisterOpen(false);
  //         Swal.fire({
  //           title: 'Registration Failed!',
  //           text: 'Please try again later.',
  //           icon: 'error',
  //           timer: 2000,
  //         });
  //       },
  //     );

  //   e.target.reset();
  // };
  const handleSubmission = (e) => {
    e.preventDefault();
    // Prevent the default form submission behavior
    setIsLoading(true);
    emailjs
      .sendForm(
        'service_vjyk2w7',
        'template_ld9mii8',
        e.target,
        'wmHveuk1GLXiFJ9Ur',
      )
      .then(
        (result) => {
          setIsLoading(false);
          setIsSubmitOpen(false);
          Swal.fire({
            title: 'Registration Successful!',
            text: 'Thank you for registering!',
            icon: 'success',
            timer: 2000,
          });
        },
        (error) => {
          setIsLoading(false);
          setIsSubmitOpen(false);
          Swal.fire({
            title: 'Registration Failed!',
            text: 'Please try again later.',
            icon: 'error',
            timer: 2000,
          });
        },
      );

    e.target.reset();
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  // useEffect(() => {
  //   const targetDate = new Date('2025-03-28T00:00:00');

  //   const calculateTimeLeft = () => {
  //     const now = new Date();
  //     const difference = targetDate.getTime() - now.getTime();

  //     if (difference > 0) {
  //       setTimeLeft({
  //         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
  //         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
  //         minutes: Math.floor((difference / 1000 / 60) % 60),
  //         seconds: Math.floor((difference / 1000) % 60),
  //       });
  //     }
  //   };

  //   const timer = setInterval(calculateTimeLeft, 1000);
  //   calculateTimeLeft(); // Initial calculation

  //   return () => clearInterval(timer);
  // }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-white">
      {/* Hero Section */}
      <header className="relative min-h-screen overflow-hidden flex items-center">
        {/* Fun Background Pattern */}
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `url('https://images.unsplash.com/photo-1550745165-9bc0b252726f?auto=format&fit=crop&q=80')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(0.9)',
          }}
        ></div>
        <div className="absolute inset-0 bg-gradient-to-b from-green-500/90 to-blue-600/90"></div>

        {/* Floating Elements */}
        <div className="absolute inset-0">
          {[...Array(15)].map((_, i) => (
            <div
              key={i}
              className="absolute animate-float"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${i * 0.3}s`,
                opacity: 0.15,
              }}
            >
              {i % 5 === 0 ? (
                <Gamepad2 className="w-16 h-16" />
              ) : i % 5 === 1 ? (
                <Rocket className="w-14 h-14" />
              ) : i % 5 === 2 ? (
                <Stars className="w-12 h-12" />
              ) : i % 5 === 3 ? (
                <Brain className="w-16 h-16" />
              ) : (
                <Lightbulb className="w-14 h-14" />
              )}
            </div>
          ))}
        </div>

        {/* Content */}
        <div className="relative container mx-auto px-4 py-12">
          <div className="max-w-5xl mx-auto text-center">
            <div
              className={`transition-all duration-1000 ${
                isVisible
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-10'
              }`}
            >
              {/* Fun Logo */}
              <div className="mb-4 flex justify-center">
                <img
                  src="/images/scratchlogo.png"
                  alt="SC25 Logo"
                  className="h-28"
                />
              </div>

              {/* Title */}
              <h1 className="text-6xl md:text-6xl font-bold mb-4 text-white animate-wiggle">
                Scratch Coding Competition 2025
              </h1>

              {/* Subtitle */}
              <div className="flex items-center justify-center gap-4 mb-8">
                <Sprout className="w-8 h-8 text-green-300" />
                <h2 className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-green-300 via-blue-200 to-purple-300 bg-clip-text text-transparent">
                  Code for a Greener Tomorrow!
                </h2>
                <Sprout className="w-8 h-8 text-green-300" />
              </div>

              {/* Description */}
              <p className="text-xl  text-white/90 leading-relaxed max-w-3xl mx-auto">
                Hey young coders! 🚀 Ready to use your awesome coding powers to
                help save our planet? Join us for an epic adventure where YOUR
                code can make the world a better place!
              </p>
              <div className="flex  my-8 gap-4 justify-center">
                {/* <button
                  className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-lg shadow-md hover:opacity-90 transition"
                  onClick={() => setIsRegisterOpen(true)}
                >
                  Register
                </button> */}
                <button
                  className="bg-gradient-to-r from-green-500 to-teal-500 text-white px-4 py-2 rounded-lg shadow-md hover:opacity-90 transition"
                  onClick={() => setIsSubmitOpen(true)}
                >
                  Submit Project
                </button>
              </div>

              {/* Countdown Timer */}
              {/* <div className="mb-16 ">
                <div className="text-xl text-white mb-4 flex items-center justify-center gap-2">
                  <Timer className="w-6 h-6 animate-pulse" />
                  <span>Project Submission:</span>
                </div>
                <div className="flex justify-center gap-4">
                  {[
                    { value: timeLeft.days, label: 'Days' },
                    { value: timeLeft.hours, label: 'Hours' },
                    { value: timeLeft.minutes, label: 'Minutes' },
                    { value: timeLeft.seconds, label: 'Seconds' },
                  ].map((item, index) => (
                    <div key={index} className="relative group">
                      <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                      <div className="relative bg-white px-4 py-3 rounded-xl min-w-[120px]">
                        <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                          {String(item.value).padStart(2, '0')}
                        </div>
                        <div className="text-sm font-medium text-gray-600">
                          {item.label}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}

              {/* CTA Button */}
            </div>
          </div>
        </div>

        {/* Fun Scroll Indicator */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 animate-bounce">
          <div className="text-white text-center flex flex-col items-center">
            <p className="mb-2 text-sm font-medium">More fun! </p>
            <ChevronDown className="w-8 h-8" />
          </div>
        </div>
      </header>

      {/* Fun Stats Section */}
      <section className="py-16 bg-gradient-to-b from-blue-600 to-purple-600 relative overflow-hidden">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage:
                'radial-gradient(circle at 10px 10px, rgba(255, 255, 255, 0.1) 2px, transparent 0)',
              backgroundSize: '20px 20px',
            }}
          ></div>
        </div>
        <div className="container mx-auto px-4 relative">
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {[
              {
                icon: <Users className="w-10 h-10" />,
                stat: '1000+',
                label: 'Young Coders',
                color: 'from-pink-400 to-red-400',
                emoji: '👩‍💻',
              },
              {
                icon: <Globe className="w-10 h-10" />,
                stat: '50+',
                label: 'Countries',
                color: 'from-green-400 to-emerald-400',
                emoji: '🌍',
              },
              {
                icon: <Sparkles className="w-10 h-10" />,
                stat: '$10,000',
                label: 'In Prizes',
                color: 'from-yellow-400 to-orange-400',
                emoji: '🏆',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl">
                    <div className="flex flex-col items-center text-center">
                      <div
                        className={`bg-gradient-to-r ${item.color} w-20 h-20 rounded-full flex items-center justify-center mb-4 text-white group-hover:scale-110 transition-transform`}
                      >
                        {item.icon}
                      </div>
                      <div className="text-4xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">
                        {item.stat} {item.emoji}
                      </div>
                      <div className="text-gray-600 font-medium">
                        {item.label}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Age Categories Section */}
      <section className="py-12 bg-gradient-to-b from-purple-600 to-purple-600 relative overflow-hidden">
        {/* Coding-Themed Background */}
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(30)].map((_, i) => (
            <div
              key={i}
              className="absolute animate-float text-white/20 font-mono text-lg"
              style={{
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                animationDelay: `${i * 0.5}s`,
                fontSize: `${Math.random() * 16 + 12}px`,
                transform: `rotate(${Math.random() * 360}deg)`,
              }}
            >
              {i % 5 === 0 ? (
                <span>{`<code>🚀</code>`}</span>
              ) : i % 5 === 1 ? (
                <span>{`console.log("Hello World!")`}</span>
              ) : i % 5 === 2 ? (
                <span>{`if (happy) { 😊 }`}</span>
              ) : i % 5 === 3 ? (
                <span>{`<ScratchBlock />`}</span>
              ) : (
                <span>{`101010`}</span>
              )}
            </div>
          ))}
        </div>
        <div className="container mx-auto px-4 relative">
          <h2 className="text-4xl font-bold text-center mb-16 text-white animate-bounce">
            Who Can Join? 🎈
          </h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-5xl mx-auto">
            {[
              {
                age: '7-8 Years',
                emoji: '👶',
                color: 'from-yellow-400 to-orange-400',
                icon: '🎨',
              },
              {
                age: '9-10 Years',
                emoji: '🧒',
                color: 'from-green-400 to-emerald-400',
                icon: '🚀',
              },
              {
                age: '11-12 Years',
                emoji: '👦',
                color: 'from-blue-400 to-indigo-400',
                icon: '🛸',
              },
              {
                age: '13-14 Years',
                emoji: '👧',
                color: 'from-pink-400 to-purple-400',
                icon: '🌟',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300 hover:rotate-2"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-shadow">
                    <div className="flex flex-col items-center text-center">
                      {/* Fun Emoji Background */}
                      <div
                        className={`bg-gradient-to-r ${item.color} w-24 h-24 rounded-full flex items-center justify-center mb-4 text-white group-hover:animate-wiggle`}
                      >
                        <span className="text-6xl">{item.emoji}</span>
                      </div>
                      {/* Age Group Title */}
                      <h3 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent mb-2">
                        {item.age}
                      </h3>
                      {/* Fun Icon */}
                      <div className="text-4xl animate-bounce">{item.icon}</div>
                      {/* Call to Action */}
                      <p className="mt-4 text-sm text-gray-600 font-medium">
                        Ready to code? Let's go! 🎉
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="py-24 bg-gradient-to-b from-purple-600 to-pink-600 relative overflow-hidden">
        <div className="container mx-auto px-4 relative">
          <h2 className="text-4xl font-bold text-center mb-16 text-white">
            Your Coding Adventure Timeline! 🚀
          </h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {[
              {
                icon: <Calendar />,
                title: 'Theme Reveal',
                date: 'February 10, 2025',
                color: 'from-yellow-400 to-orange-400',
              },
              {
                icon: <Code2 />,
                title: 'Coding Time!',
                date: 'March 01-28, 2025',
                color: 'from-green-400 to-emerald-400',
              },
              {
                icon: <Trophy />,
                title: 'Winners Party!',
                date: 'April 4, 2025',
                color: 'from-pink-400 to-purple-400',
              },
            ].map((item, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl">
                    <div
                      className={`bg-gradient-to-r ${item.color} w-16 h-16 rounded-full flex items-center justify-center mb-4 mx-auto text-white`}
                    >
                      {item.icon}
                    </div>
                    <h3 className="text-2xl font-bold mb-3 text-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                      {item.title}
                    </h3>
                    <p className="text-center text-gray-600 font-medium">
                      {item.date}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Rules Section */}
      <section className="py-24 bg-gradient-to-b from-pink-600 to-red-600 relative overflow-hidden">
        <div className="container mx-auto px-4 relative">
          <h2 className="text-4xl font-bold text-center mb-16 text-white">
            Game Rules! 🎮
          </h2>
          <div className="max-w-5xl mx-auto grid md:grid-cols-2 gap-12">
            {[
              {
                title: 'How to Win Points! 🌟',
                items: [
                  'Cool theme ideas',
                  'Awesome story and gameplay',
                  'Amazing graphics and sounds',
                  'Super clean code',
                  'WOW factor!',
                ],
              },
              {
                title: 'Important Stuff to Remember! 📝',
                items: [
                  'Solo adventure - no teams',
                  'Use Scratch 3.0',
                  'No extra add-ons',
                  'Make it yourself',
                  'Create during contest time',
                ],
              },
            ].map((section, index) => (
              <div
                key={index}
                className="transform hover:scale-105 transition-all duration-300"
              >
                <div className="relative group">
                  <div className="absolute -inset-1 bg-gradient-to-r from-pink-400 via-purple-400 to-indigo-400 rounded-2xl blur opacity-75 group-hover:opacity-100 transition duration-200"></div>
                  <div className="relative bg-white p-8 rounded-2xl shadow-xl">
                    <h3 className="text-2xl font-bold mb-4 text-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                      {section.title}
                    </h3>
                    <ul className="space-y-4">
                      {section.items.map((item, i) => (
                        <li key={i} className="flex items-center gap-3 group">
                          <div className="w-3 h-3 bg-gradient-to-r from-pink-400 to-purple-400 rounded-full group-hover:scale-125 transition-transform"></div>
                          <span className="text-gray-700 group-hover:text-purple-600 transition-colors font-medium">
                            {item}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Fun Footer */}
      <footer className="bg-gradient-to-b from-red-600 to-pink-600 text-white py-16 relative overflow-hidden">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage:
                'radial-gradient(circle at center, rgba(255,255,255,0.2) 0%, transparent 2%)',
              backgroundSize: '3rem 3rem',
            }}
          ></div>
        </div>
        <div className="container mx-auto px-4 relative">
          <div className="text-center">
            <div className="flex justify-center items-center gap-4 mb-8">
              <Heart className="w-8 h-8 text-pink-300 animate-pulse" />
              <h2 className="text-3xl font-bold">
                Ready to Code and Save the Planet?
              </h2>
              <Heart className="w-8 h-8 text-pink-300 animate-pulse" />
            </div>
            <p className="text-xl mb-8 text-pink-200">
              Let's make the world better, one block of code at a time! 🌍
            </p>
            <div className="flex justify-center gap-4">
              {[Rocket, Stars, Brain, Lightbulb].map((Icon, index) => (
                <div
                  key={index}
                  className="animate-bounce-rotate"
                  style={{ animationDelay: `${index * 0.2}s` }}
                >
                  <Icon className="w-8 h-8 text-pink-300" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </footer>
      {/* {
        // Register Modal
        isRegisterOpen && (
          <div className="fixed inset-0 z-99 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-xl relative max-w-md w-full">
              <X
                className="absolute w-6 h-6 text-red-500 cursor-pointer  top-4 right-4"
                onClick={() => setIsRegisterOpen(false)}
              />
              <form onSubmit={handleRegister}>
                <img src="/images/logo.png" className="mx-auto h-16" alt="" />
                <h2 className="text-center font-bold mb-4">Kids Information</h2>
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="kids_first_name"
                      id="kids_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="kids_first_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      First name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="kids_last_name"
                      id="kids_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="kids_last_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Last name
                    </label>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <label
                      htmlFor="grade"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Grade
                    </label>
                    <div className="">
                      <input
                        type="text"
                        name="grade"
                        id="grade"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                    </div>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <label
                      htmlFor="gender"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Gender
                    </label>
                    <div className="mt-2">
                      <select
                        id="gender"
                        name="gender"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <div className="mt-2">
                      <input
                        type="number"
                        name="age"
                        id="age"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="age"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Age
                      </label>
                    </div>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <div className="mt-2">
                      <input
                        type="text"
                        name="school"
                        id="school"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        required
                      />
                      <label
                        htmlFor="school"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        School
                      </label>
                    </div>
                  </div>
                </div>
                <h2 className="text-center font-bold my-4">
                  Parent/Guardian Information
                </h2>
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="parent_first_name"
                      id="parent_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="parent_first_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      First name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="parent_last_name"
                      id="parent_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="parent_last_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Last name
                    </label>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="tel"
                      pattern="[0-9]{10}|\+[0-9]{12}"
                      name="parent_phone"
                      id="parent_phone"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      required
                    />
                    <label
                      htmlFor="parent_phone"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Phone number{' '}
                      <span className="hidden ">(073-456-7890)</span>
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="email"
                      name="parent_email"
                      id="parent_email"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="parent_email"
                      className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email address
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isloading}
                  className="
                  text-white bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  //onClick={handleRegister}
                >
                  {isloading ? 'Loading...' : 'Register'}
                </button>
              </form>
            </div>
          </div>
        )
      } */}
      {
        // Register Modal
        isSubmitOpen && (
          <div className="fixed inset-0 z-99 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-8 rounded-xl relative max-w-md w-full">
              <X
                className="absolute w-6 h-6 text-red-500 cursor-pointer  top-4 right-4"
                onClick={() => setIsSubmitOpen(false)}
              />
              <form onSubmit={handleSubmission}>
                <img src="/images/logo.png" className="mx-auto h-16" alt="" />
                <h2 className="text-center font-bold mb-4">Kids Information</h2>
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="kids_first_name"
                      id="kids_first_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="kids_first_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      First name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="kids_last_name"
                      id="kids_last_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="kids_last_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Last name
                    </label>
                  </div>
                </div>

                <h2 className="text-center font-bold my-4">
                  Parent/Guardian Information
                </h2>

                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="tel"
                      pattern="[0-9]{10}|\+[0-9]{12}"
                      name="parent_phone"
                      id="parent_phone"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                      required
                    />
                    <label
                      htmlFor="parent_phone"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Phone number{' '}
                      <span className="hidden ">(073-456-7890)</span>
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="email"
                      name="parent_email"
                      id="parent_email"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="parent_email"
                      className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email address
                    </label>
                  </div>
                </div>
                <h2 className="text-center font-bold my-4">Project</h2>
                <div className="grid md:grid-cols-2 md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="project_name"
                      id="project_name"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="project_name"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Project Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="project_link"
                      id="project_description"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="project_link"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Project Link
                    </label>
                  </div>
                </div>
                <div className="grid md:gap-4">
                  <div className="relative z-0 w-full mb-4 group">
                    <input
                      type="text"
                      name="project_description"
                      id="project_description"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="project_description"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Project Description
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isloading}
                  className="
                  text-white bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  //onClick={handleRegister}
                >
                  {isloading ? 'Loading...' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        )
      }
      {/* <p>Dear <strong>{{kids_first_name}}</strong>,:</p>
    <h1 style="color: #27ae60;">🎉 Thank You for Registering!</h1>
          <p style="font-size: 16px; color: #333; line-height: 1.6;">
              We are thrilled to have you on board for the <strong style="color: #27ae60;">Funlings Coding Competition 2025</strong>!
          </p>
          
          <h2 style="color: #333;">Get Started on Your Project!</h2>
          <p style="font-size: 16px; color: #333; line-height: 1.6;">
              This year’s theme is <strong style="color: #27ae60;">“Code for a Greener Tomorrow” 🌍</strong>. Use Scratch to create a game, animation, or app that promotes environmental sustainability. Let your creativity shine as you develop innovative tech solutions to help make the world a greener place!
          </p>
  
          <p style="font-size: 16px; color: #333; line-height: 1.6;">
              <strong>Project submissions close on March 28, 2025.</strong> Make sure to submit your project before the deadline.
          </p>
  
          <h2 style="color: #333;">What’s Next?</h2>
          <p style="font-size: 16px; color: #333; line-height: 1.6;">
              Our team will review all submissions, and finalists will be contacted by <strong style="color: #27ae60;">April 1, 2025</strong>. They will be invited to a physical venue in Nairobi to present and defend their projects on <strong style="color: #27ae60;">April 5, 2025</strong>. The competition will conclude on the same day with an exciting awards ceremony.
          </p>
  
          <p style="font-size: 16px; color: #333; line-height: 1.6;">
              We can’t wait to see what you create! If you have any questions, feel free to reach out.
          </p>
  
          <p style="margin-top: 20px; font-size: 14px; color: #777;">
              Happy coding! 🚀<br><br>
              Best regards,<br>
              <strong>Funlings Team</strong><br>
              📞 +254 720 472 635
          </p> */}
      {/* <h2 style="color: #27ae60; text-align: center;">🎉 New Registration Details</h2>

        <p style="font-size: 16px; color: #333; line-height: 1.6;">
            A new participant has registered. Below are the details:
        </p>

        <table style="width: 100%; border-collapse: collapse;">
            <tr>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;"><strong>Kid's Name:</strong></td>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;">{{kids_first_name}} {{kids_last_name}}</td>
            </tr>
            <tr>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;"><strong>Grade:</strong></td>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;">{{grade}}</td>
            </tr>
            <tr>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;"><strong>Gender:</strong></td>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;">{{gender}}</td>
            </tr>
            <tr>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;"><strong>Age:</strong></td>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;">{{age}}</td>
            </tr>
            <tr>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;"><strong>School:</strong></td>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;">{{school}}</td>
            </tr>
            <tr>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;"><strong>Parent's Name:</strong></td>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;">{{parent_first_name}} {{parent_last_name}}</td>
            </tr>
            <tr>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;"><strong>Parent's Phone:</strong></td>
                <td style="padding: 8px; border-bottom: 1px solid #ddd;">{{parent_phone}}</td>
            </tr>
            <tr>
                <td style="padding: 8px;"><strong>Parent's Email:</strong></td>
                <td style="padding: 8px;">{{parent_email}}</td>
            </tr>
        </table>

        <p style="margin-top: 20px; font-size: 14px; color: #777;">
            Please verify the details and take necessary action.
        </p>

        <p style="margin-top: 20px; font-size: 14px; color: #777; text-align: center;">
            Best regards, <br>
            <strong>Funlings Team</strong>
        </p> */}
    </div>
  );
}

export default Competitions;
