import React, { useEffect } from 'react';
import About from '../components/About';
import Blog from '../components/Blog';
import Courses from '../components/Courses';
import Hero from '../components/Hero';
import Partners from '../components/Partners';
import Statistics from '../components/Statistics';
import FAQS from '../components/FAQS';
import Team2 from '../components/Team2';
import Testimonials2 from '../components/Testimonials2';

const Home = () => {
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    const date = new Date();
    // Add 1 minute to the current date
    //const expiry = new Date(date.getTime() + 1 * 60 * 1000);
    // add a day to the current date
    const expiry = new Date(date.getTime() + 1 * 24 * 60 * 60 * 1000);
    localStorage.setItem('marketing-banner', expiry.toString());
    setShow(false);
  };

  useEffect(() => {
    const storedDate = localStorage.getItem('marketing-banner');
    const currentDate = new Date();

    console.log(storedDate);
    console.log(currentDate);

    if (!storedDate) {
      // If there is no stored date, show the banner
      setShow(true);
    } else {
      const expiryDate = new Date(storedDate);
      console.log(expiryDate);
      // Compare the current date with the expiry date
      if (currentDate > expiryDate) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, []);
  return (
    <div>
      {show && (
        <div
          id="marketing-banner"
          className="fixed z-50 flex flex-col md:flex-row justify-between w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-gradient-to-r from-purple-600 to-pink-600 border border-gray-100 rounded-lg shadow-lg lg:max-w-7xl left-1/2 top-6 animate-bounce-in"
        >
          <div className="flex flex-col items-start mb-3 me-4 md:items-center md:flex-row md:mb-0">
            <a
              href="/#/competitions"
              onClick={handleClick}
              className="flex items-center mb-2 border-gray-200 md:pe-4 md:me-4 md:border-e md:mb-0 hover:scale-105 transition-transform"
            >
              {/* Fun Logo or Icon */}
              <span className="text-3xl mr-2">🚀</span>
              <span className="self-center text-lg font-bold whitespace-nowrap text-white">
                World Scratch Coding Competition 2025
              </span>
            </a>
            <p className="flex items-center text-sm font-normal text-gray-100">
              <span className="text-2xl mr-2">🎉</span>
              <span>
                <span className="font-semibold">Code</span> for a Greener
                Tomorrow! 🌍
              </span>
            </p>
          </div>
          <div className="flex items-center flex-shrink-0">
            <a
              href="/#/competitions"
              onClick={handleClick}
              className="px-5 py-2 me-2 text-sm font-medium text-white bg-yellow-500 rounded-lg hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 focus:outline-none transition-all hover:scale-105 shadow-md"
            >
              Register Now
            </a>
            <button
              data-dismiss-target="#marketing-banner"
              type="button"
              onClick={handleClick}
              className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-100 hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-1.5 transition-all hover:scale-110"
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close banner</span>
            </button>
          </div>
        </div>
      )}
      <Hero />
      <About />
      <Courses />
      <Blog />
      <Partners />
      <Testimonials2 />
      <Statistics />
      <Team2 />
      <FAQS />
    </div>
  );
};

export default Home;
